import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { App, RestoredListenerEvent } from '@capacitor/app';
import { Storage } from '@ionic/storage-angular';
import { VasJobDto } from '@ironcode/vas-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface FormStateModel {
  route: string;
  formData: VasJobDto;
  lastControl: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormStateService {

  protected stopSubject = new Subject();
  readonly stateKey = 'form-state-service';
  protected storageService: Storage;
  public pluginData: RestoredListenerEvent;

  constructor(
    protected router: Router,
    protected storageServiceProvider: Storage
  ) {

  }

  async clearState(): Promise<void> {
    const db = await this.getDb();
    return db.set(this.stateKey, null);
  }

  protected async getDb(): Promise<Storage> {
    if (this.storageService) {
      return this.storageService;
    }
    return this.storageServiceProvider
      .create()
      .then(storage => this.storageService = storage);
  }

  async getState(): Promise<FormStateModel> {
    const db = await this.getDb();
    return db.get(this.stateKey)
      .then(result => result);
  }

  monitorStart(formGroup: UntypedFormGroup): void {
    console.debug('[FormStateService] monitorStart');
    formGroup.valueChanges.pipe(
      takeUntil(this.stopSubject)
    ).subscribe(formData => {
      this.updateState({formData}).catch(console.error);
    });
    this.setState({
      formData: formGroup.value,
      lastControl: undefined,
      route: this.router.url
    });
  }

  async init(): Promise<void> {
    App.addListener('appRestoredResult', (event: RestoredListenerEvent) => {
      this.restoreState(event);
    });

    const previousState = await this.getState();
    if (previousState) {
      console.debug('[FormStateService] found previous state, routing');
      await this.router.navigateByUrl(previousState.route);
    }
  }

  monitorStop(): void {
    console.debug('[FormStateService] monitorStart');
    this.stopSubject.next(undefined);
    this.stopSubject.complete();
    this.clearState().catch(console.error);
  }

  /**
   * This method should be called from the handler of appRestoredResult
   * See https://capacitorjs.com/docs/apis/app#addlistenerapprestoredresult
   * @param {RestoredListenerEvent} event
   */
  protected restoreState(event: RestoredListenerEvent): void {
    console.debug('[FormStateService] restoreState', event);
    this.pluginData = event;
    this.getState()
      .then(state => {
        if (!state) {
          return;
        }
        this.router.navigateByUrl(state.route);
      });
  }

  /**
   * Sets the state. Helper method
   * @param {FormStateModel} state
   * @return {Promise<void>}
   * @protected
   */
  protected async setState(state: FormStateModel): Promise<void> {
    const db = await this.getDb();
    await db.set(this.stateKey, state);
  }

  /**
   * Merges state with previously saved state
   * @param {Partial<FormStateModel>} state
   * @return {Promise<void>}
   * @protected
   */
  async updateState(state: Partial<FormStateModel>): Promise<void> {
    const previous = await this.getState();
    return this.setState({
      ...previous,
      ...state
    });
  }
}
