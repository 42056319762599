import { Observable } from 'rxjs';
import { BaseCrudController } from './base-crud.controller';
import { HttpClient } from '@angular/common/http';
import { VasControlTypeDto } from '@ironcode/vas-lib';

/**
 * ControlTypeController
 */
export class ControlTypeController extends BaseCrudController {
  /**
   * @param {string} host
   * @param {string} apiVersion
   * @param {HttpClient} http
   */
  constructor(
    protected host: string,
    protected apiVersion: string,
    protected http: HttpClient
  ) {
    super(host, apiVersion, http, 'controltypes');
  }


  /**
   * @inheritDoc
   */
  create(): Observable<Object> {
    throw Error('not implemented');
  }

  /**
   * @inheritDoc
   */
  delete(): Observable<Object> {
    throw Error('not implemented');
  }

  /**
   * @inheritDoc
   */
  read(): Observable<Array<VasControlTypeDto>> {
    return this.http.get<Array<VasControlTypeDto>>(
      this.r(this.entityName)
    );
  }

  /**
   * @inheritDoc
   */
  readOne(id: string): Observable<VasControlTypeDto> {
    return this.http.get<VasControlTypeDto>(
      this.r(`${this.entityName}/${id}`)
    );
  }

  /**
   * @inheritDoc
   */
  update(): Observable<Object> {
    throw Error('not implemented');
  }
}
