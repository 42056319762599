import { BaseCrudController } from './base-crud.controller';
import { Observable, of, throwError } from 'rxjs';
import { VasAccountObjectDto } from '@ironcode/vas-lib';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * AccountObjectBaseCrudController
 */
export abstract class AccountObjectBaseCrudController<T extends VasAccountObjectDto> extends BaseCrudController {
  /**
   * @inheritDoc
   */
  create(
    accountId: string,
    object: T
  ): Observable<T> {
    return this.http.post<T>(
      this.r(`accounts/${accountId}/${this.entityName}`),
      {
        invitation: object
      }
    );
  }

  /**
   * @inheritDoc
   */
  delete(accountId: string, id: string): Observable<object> {
    return this.http.delete(
      this.r(`accounts/${accountId}/${this.entityName}/${id}`), {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json'
        }
      }
    );
  }

  /**
   * @inheritDoc
   */
  read(accountId: string): Observable<Array<T>> {
    return this.http.get<Array<T>>(
      this.r(`accounts/${accountId}/${this.entityName}`)
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        }
        return throwError(error);
      })
    );
  }

  /**
   * @inheritDoc
   */
  readOne(accountId: string, id: string): Observable<T> {
    return this.http.get<T>(
      this.r(`accounts/${accountId}/${this.entityName}/${id}`)
    );
  }

  /**
   * @inheritDoc
   */
  update(
    accountId: string,
    id: string,
    object: Partial<T>
  ): Observable<object> {
    return this.http.patch(
      this.r(`accounts/${accountId}/${this.entityName}/${id}`),
      {
        [this.entityName]: object
      }
    );
  }
}
