import { HttpClient } from '@angular/common/http';
import { VasApi } from '../vas-api.service';

/**
 * BaseCrudController
 */
export abstract class BaseCrudController {
  /**
   * @param {string} host
   * @param {string} apiVersion
   * @param {HttpClient} http
   * @param {string} entityName
   */
  constructor(
    protected host: string,
    protected apiVersion: string,
    protected http: HttpClient,
    protected entityName: string
  ) {
  }

  /**
   * Create the api route
   * @param {string} path
   * @return {string} the route
   */
  r(path: string): string {
    return VasApi.r(
      this.host,
      this.apiVersion,
      path
    );
  }
}
