import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateStorageService } from './state-storage.service';
import { PersistableRepository } from './persistable.repository';
import { VasReportLayoutDto } from '@ironcode/vas-lib';
import { AccountRepository } from './account.repository';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportLayoutRepository extends PersistableRepository {
  reportLayouts$: Observable<VasReportLayoutDto[]>;

  protected store;

  constructor(
    protected stateStorageService: StateStorageService,
    protected accountRepo: AccountRepository
  ) {
    super(stateStorageService);
    const store = this.createStore();
    this.reportLayouts$ = combineLatest([
      store.pipe(selectAllEntities()),
      this.accountRepo.selectedAccountId$
    ]).pipe(
      map(([items, selectedAccountId]) => items
        .filter(item => item.account === selectedAccountId)
      )
    );
    this.store = store;
  }

  addReportLayout(
    reportLayout: VasReportLayoutDto
  ): void {
    this.store.update(addEntities(reportLayout));
  }

  deleteReportLayout(
    id: VasReportLayoutDto['id']
  ): void {
    this.store.update(deleteEntities(id));
  }

  getReportLayout(
    id: VasReportLayoutDto['id']
  ): Observable<VasReportLayoutDto> {
    return this.store.pipe(selectEntity(id));
  }

  setReportLayouts(
    reportLayouts: VasReportLayoutDto[]
  ): void {
    this.store.update(setEntities(reportLayouts));
  }

  clearAndSet(reportLayouts: VasReportLayoutDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {}
      }),
      setEntities(reportLayouts)
    );
  }

  updateReportLayout(
    id: VasReportLayoutDto['id'],
    reportLayout: Partial<VasReportLayoutDto>
  ): void {
    this.store.update(updateEntities(id, reportLayout));
  }

  upsertReportLayouts(
    reportLayouts: VasReportLayoutDto[]
  ): void {
    this.store.update(upsertEntities(reportLayouts));
  }

  persist(): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    return this._persist('reportLayout');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'reportLayout'}, withEntities<VasReportLayoutDto>());

    return store;
  }
}
