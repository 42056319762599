import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VasApi } from './vas/api/vas-api.service';
import { VasAccountDto } from '@ironcode/vas-lib';

@Injectable({
  providedIn: 'root'
})
export class AccountDataService {

  constructor(
    protected vasApi: VasApi
  ) {
  }

  getAll(): Observable<VasAccountDto[]> {
    return this.vasApi.account.read();
  }
}
