import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateStorageService } from './state-storage.service';
import { PersistableRepository } from './persistable.repository';
import { VasFormDto, VasFormModel } from '@ironcode/vas-lib';
import { map } from 'rxjs/operators';
import { ControlTypeRepository } from './control-type.repository';
import { AccountRepository } from './account.repository';

@Injectable({
  providedIn: 'root'
})
export class FormRepository extends PersistableRepository {
  /**
   * An observable of form models with control type models set into each control
   * model
   * @type {Observable<VasFormModel[]>}
   */
  formModels$: Observable<VasFormModel[]>;
  forms$: Observable<VasFormDto[]>;
  protected store;

  constructor(
    protected stateStorageService: StateStorageService,
    protected accountRepo: AccountRepository,
    protected controlTypeRepo: ControlTypeRepository
  ) {
    super(stateStorageService);
    const store = this.createStore();

    this.forms$ = combineLatest([
      store.pipe(selectAllEntities()),
      this.accountRepo.selectedAccountId$
    ]).pipe(
      map(([items, selectedAccountId]) => items
        .filter(item => item.account === selectedAccountId)
      )
    );

    this.formModels$ = combineLatest(([
      this.forms$,
      this.controlTypeRepo.controlTypes$,
    ])).pipe(
      map(([forms, controlTypes]) => {
        return forms
          .map(form => {
            const formModel = VasFormModel.fromDto(form);
            formModel.addControlTypesToControl(controlTypes);
            return formModel;
          });
      }),
      map(forms => forms.sort((a, b) => a.title.localeCompare(b.title)))
    );
    this.store = store;
  }

  addForm(form: VasFormDto) {
    this.store.update(addEntities(form));
  }

  deleteForm(id: VasFormDto['id']) {
    this.store.update(deleteEntities(id));
  }

  getForm(id: VasFormDto['id']): Observable<VasFormDto> {
    return this.store.pipe(selectEntity(id));
  }

  setForms(forms: VasFormDto[]) {
    this.store.update(setEntities(forms));
  }

  clearAndSet(forms: VasFormDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {}
      }),
      setEntities(forms)
    );
  }

  updateForm(id: VasFormDto['id'], form: Partial<VasFormDto>) {
    this.store.update(updateEntities(id, form));
  }

  upsertForms(forms: VasFormDto[]): void {
    this.store.update(upsertEntities(forms));
  }

  persist(): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    return this._persist('form');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'form'}, withEntities<VasFormDto>());

    return store;
  }
}
