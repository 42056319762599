import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertController, LoadingController, NavController, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    public http: HttpClient,
    public loadingController: LoadingController,
    public fireAuth: Auth,
    public router: Router,
    public toastController: ToastController,
    public nav: NavController,
    public alertController: AlertController,
  ) {
  }

  async areYouSure(
    okCallback: () => void,
    cancelCallback?: () => void,
    message = 'Are you sure?',
    header = 'Confirm',
  ): Promise<any> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: () => {
            if (!cancelCallback) {
              alert.dismiss();
              return;
            }
            cancelCallback();
            alert.dismiss();
          }
        }, {
          text: 'Ok',
          handler: () => {
            if (!okCallback) {
              alert.dismiss();
              return;
            }
            okCallback();
            alert.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  navigate(link: string, forward?): void {
    if (forward) {
      this.nav.navigateForward('/' + link);
    } else {
      this.router.navigateByUrl('/' + link);
    }
  }

  validateEmail(email): boolean {
    // eslint-disable-next-line no-useless-escape, max-len
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  async presentToast(
    message,
    showButton = false,
    position: any = 'bottom',
    duration = 1000
  ): Promise<any> {
    const toast = await this.toastController.create({
      message,
      buttons: !showButton ? [] : [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            toast.dismiss();
          }
        }
      ],
      position,
      duration
    });
    toast.present();
  }


  removeConform(): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: 'Confirm!',
        message: 'Are you sure you want to remove this item',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {

              resolve('cancel');
            }
          }, {
            text: 'Okay',
            handler: (ok) => {

              resolve('ok');
            }
          }
        ]
      });

      alert.present();
    });
  }

  async openLoader(): Promise<any> {
    const loading = await this.loadingController.create({
      message: 'Please Wait ...'
    });
    await loading.present();
  }

  async closeLoading(): Promise<any> {
    // eslint-disable-next-line no-return-await
    try {
      return await this.loadingController.dismiss();
    } catch (e) {
      return;
    }
  }

  // Sample API response for content loading
  getApiResponse(): Observable<any> {
    const myUrl = 'http://www.mocky.io/v2/5c9215a73200005d006bccee?mocky-delay=5000ms';
    return this.http.get(myUrl);
  }

  // Sample API response for Infinite scrolling
  infiniteData(): Observable<any> {
    const myUrl = 'http://www.mocky.io/v2/5c9448a0310000a45b55487c?mocky-delay=5000ms';
    return this.http.get(myUrl);
  }
}
