import { ErrorHandler, Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { AccountRepository } from '../state/account.repository';
import { BranchRepository } from '../state/branch.repository';
import { ContactRepository } from '../state/contact.repository';
import { ControlTypeRepository } from '../state/control-type.repository';
import { FormRepository } from '../state/form.repository';
import { JobStatusRepository } from '../state/job-status.repository';
import { ReportLayoutRepository } from '../state/report-layout.repository';
import { AccountDataService } from './account-data.service';
import { BranchDataService } from './branch-data.service';
import { ContactDataService } from './contact-data.service';
import { ControlTypeDataService } from './control-type-data.service';
import { AuthenticationService } from './firebase-authentication.service';
import { FormDataService } from './form-data.service';
import { JobStatusDataService } from './job-status-data.service';
import { ReportLayoutDataService } from './report-layout-data.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  readonly TAG = '[MasterDataService]';
  protected branchSub: Subscription | undefined;
  protected loaded = false;
  loading = false;

  constructor(
    protected authService: AuthenticationService,
    protected accountDataService: AccountDataService,
    protected formDataService: FormDataService,
    protected controlTypeDataService: ControlTypeDataService,
    protected reportLayoutDataService: ReportLayoutDataService,
    protected branchDataService: BranchDataService,
    protected contactDataService: ContactDataService,
    protected accountRepo: AccountRepository,
    protected jobStatusDataService: JobStatusDataService,
    protected formRepo: FormRepository,
    protected controlTypeRepo: ControlTypeRepository,
    protected reportLayoutRepo: ReportLayoutRepository,
    protected branchRepo: BranchRepository,
    protected contactRepo: ContactRepository,
    protected jobStatusRepo: JobStatusRepository,
    protected errorHandler: ErrorHandler
  ) {
  }

  firstLoad(): Observable<any> {

    if (!this.authService.currentUser) {
      return of(undefined);
    }

    if (this.loaded) {
      return of(undefined);
    }
    return this.loadMasterData().pipe(
      tap(() => this.loaded = true)
    );
  }

  /**
   * This method will load master data into the app
   * @return {Observable<any>}
   */
  loadMasterData(): Observable<any> {
    console.debug('[MasterDataService] load start');
    return fromPromise(Network.getStatus()).pipe(
      tap(status => {
        if (!status.connected) {
          throw Error('No network connection');
        }
        this.loading = true;
      }),
      concatMap(() => {
        return forkJoin([
          this.accountDataService.getAll()
            .pipe(
              take(1),
              catchError(() => of([])),
              tap(items => this.accountRepo.clearAndSet(items))
            ),
          this.formDataService.getAll()
            .pipe(
              take(1),
              catchError(() => of([])),
              tap(items => this.formRepo.clearAndSet(items))
            ),
          this.controlTypeDataService.getAll()
            .pipe(
              take(1),
              catchError(() => of([])),
              tap(items => this.controlTypeRepo.clearAndSet(items))
            ),
          this.reportLayoutDataService.getAll('')
            .pipe(
              take(1),
              catchError(() => of([])),
              tap(items => this.reportLayoutRepo.clearAndSet(items))
            ),

          this.contactDataService.getAll()
            .pipe(
              take(1),
              catchError(() => of([])),
              tap(items => this.contactRepo.clearAndSet(items))
            ),
          this.jobStatusDataService.getAll()
            .pipe(
              take(1),
              catchError(() => of([])),
              tap(items => this.jobStatusRepo.clearAndSet(items))
            )
        ]);
      }),
      tap((
        [
          accounts,
          forms,
          controlTypes,
          reportLayouts,
          contacts,
          jobStatuses
        ]) => {
        console.debug(this.TAG, 'accounts %s', accounts.length);
        console.debug(this.TAG, 'forms %s', forms.length);
        console.debug(this.TAG, 'controlTypes %s', controlTypes.length);
        console.debug(this.TAG, 'reportLayouts %s', reportLayouts.length);
        console.debug(this.TAG, 'contacts %s', contacts.length);
        console.debug(this.TAG, 'jobStatuses %s', jobStatuses.length);

        this.initBranches();

        this.loading = false;
      }),
      catchError(error => {
        this.loading = false;

        if (error.message === 'No network connection') {
          console.warn(this.TAG, 'no network connection');
          return;
        }

        this.errorHandler.handleError(error);
        return throwError(error);
      })
    );
  }

  /**
   * Initialise firebase query to keep branch data in sync.
   *
   * Branches, unlike all other master data, come from firebase, and firebase
   * works a little differently when it comes to keeping in sync with the
   * server. With firebase, we must keep the observable open to allow the data
   * to settle. Otherwise, what happens if we only listen to the first response,
   * we will only ever get results from cache. Thus, we need to keep the channel
   * open to allow firebase to eventually synchronise the data.
   */
  initBranches(): void {
    if (this.branchSub) {
      this.branchSub.unsubscribe();
    }
    console.debug(this.TAG, 'init branches');
    this.branchSub = this.branchDataService.get()
      .pipe(
        catchError(() => of([])),
        tap(branches => console.debug(this.TAG, 'branches %s', branches.length))
      ).subscribe(items => this.branchRepo.clearAndSet(items));
  }
}
