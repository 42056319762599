import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { VasInvitationDto } from '@ironcode/vas-lib';
import { AccountObjectBaseCrudController } from './account-object-base-crud.controller';

/**
 * InvitationController
 */
export class InvitationController extends AccountObjectBaseCrudController<VasInvitationDto> {
  /**
   * @inheritDoc
   */
  constructor(
    protected host: string,
    protected apiVersion: string,
    protected http: HttpClient
  ) {
    super(host, apiVersion, http, 'invitations');
  }

  /**
   * @inheritDoc
   */
  update(
    accountId: string,
    id: string,
    object: Partial<VasInvitationDto>
  ): Observable<VasInvitationDto> {
    throw Error('not implemented');
  }

  /**
   * @param secret
   */
  accept(secret: string): Observable<VasInvitationDto> {
    return this.http.get<any>(
      this.r(`${this.entityName}/${secret}`)
    );
  }
}
