import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import localForage from 'localforage';
import { environment } from '../environments/environment';
import { HomePageRepository } from './pages/home/home.page.repository';
import { AuthenticationService } from './services/firebase-authentication.service';
import { FormStateService } from './services/form-state.service';
import { GoogleAnalyticsPageViewService } from './services/google-analytics-page-view.service';
import { MasterDataService } from './services/master-data.service';
import { UploadQueueService } from './services/upload-queue.service';
import { AccountRepository } from './state/account.repository';
import { BranchRepository } from './state/branch.repository';
import { ContactRepository } from './state/contact.repository';
import { ControlTypeRepository } from './state/control-type.repository';
import { FormRepository } from './state/form.repository';
import { JobStatusRepository } from './state/job-status.repository';
import { JobRepository } from './state/job.repository';
import { ReportLayoutRepository } from './state/report-layout.repository';
import { SaveQueueRepository } from './state/save-queue-repository.service';
import { StateStorageService } from './state/state-storage.service';
import { PermsService } from './services/perms.service';
import { ErrorHandler } from '@angular/core';

export const appInitialise = (
  errorHandler: ErrorHandler,
  authService: AuthenticationService,
  uploadQueueService: UploadQueueService,
  stateStorage: StateStorageService,
  accountRepo: AccountRepository,
  formRepo: FormRepository,
  controlTypeRepo: ControlTypeRepository,
  jobRepo: JobRepository,
  reportLayoutRepo: ReportLayoutRepository,
  branchRepo: BranchRepository,
  contactRepo: ContactRepository,
  jobStatusRepo: JobStatusRepository,
  homePageRepo: HomePageRepository,
  saveQueueRepo: SaveQueueRepository,
  masterDataService: MasterDataService,
  gaPageViewService: GoogleAnalyticsPageViewService,
  formStateService: FormStateService,
  permsService: PermsService,
  router: Router
) => async () => {
  console.debug('appInitialise');
  try {
    stateStorage.storage = localForage.createInstance({
      driver: localForage.INDEXEDDB,
      name: environment.localForage.name,
      version: environment.localForage.version,
      storeName: environment.localForage.storeName
    });
  } catch (e) {
    errorHandler.handleError(e);
  }

  try {
    authService.init();
  } catch (e) {
    errorHandler.handleError(e);
  }

  try {
    await accountRepo.persist().initialized$.toPromise();
    await formRepo.persist().initialized$.toPromise();
    await controlTypeRepo.persist().initialized$.toPromise();
    await jobRepo.persist().initialized$.toPromise();
    await reportLayoutRepo.persist().initialized$.toPromise();
    await saveQueueRepo.persist().initialized$.toPromise();
    await branchRepo.persist().initialized$.toPromise();
    await contactRepo.persist().initialized$.toPromise();
    await jobStatusRepo.persist().initialized$.toPromise();
    await homePageRepo.persist().initialized$.toPromise();
  } catch (e) {
    errorHandler.handleError(e);
  }

  try {
    gaPageViewService.watchNavigation();
  } catch (e) {
    errorHandler.handleError(e);
  }

  try {
    await uploadQueueService.houseKeeping();
    uploadQueueService.startUploadInterval();
  } catch (e) {
    errorHandler.handleError(e);
  }


  try {
    if (Capacitor.isPluginAvailable('BackgroundFetch')) {
      await uploadQueueService.initBackgroundFetch();
      uploadQueueService.logBackgroundFetchStatus();
    }
  } catch (e) {
    errorHandler.handleError(e);
  }

  try {
    await formStateService.init();
  } catch (e) {
    errorHandler.handleError(e);
  }
  try {
    permsService.init();
  } catch (e) {
    errorHandler.handleError(e);
  }
};
