import { AccountObjectBaseCrudController } from './account-object-base-crud.controller';
import { VasJobStatusDto } from '@ironcode/vas-lib';
import { HttpClient } from '@angular/common/http';

/**
 * FormController
 */
export class JobStatusController extends AccountObjectBaseCrudController<VasJobStatusDto> {
  /**
   * @param {string} host
   * @param {string} apiVersion
   * @param {HttpClient} http
   */
  constructor(
    protected host: string,
    protected apiVersion: string,
    protected http: HttpClient
  ) {
    super(host, apiVersion, http, 'jobstatuses');
  }
}
