import { createStore, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateStorageService } from './state-storage.service';
import { PersistableRepository } from './persistable.repository';
import { VasAccountDto } from '@ironcode/vas-lib';
import { map } from 'rxjs/operators';
import { Store } from '@ngneat/elf/lib/store';

interface AccountRepositoryProps {
  selectedAccount: string | undefined;
  onboarding: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AccountRepository extends PersistableRepository {
  accounts$: Observable<VasAccountDto[]>;
  selectedAccountId$: Observable<string>;
  onboarding$: Observable<boolean>;

  store: Store<{
    name: string;
    state: AccountRepositoryProps & {
      ids: Array<VasAccountDto['id']>;
      entities: Record<VasAccountDto['id'], VasAccountDto>;
    };
    config: AccountRepositoryProps;
  }>;

  constructor(
    protected stateStorageService: StateStorageService
  ) {
    super(stateStorageService);
    const store = this.createStore();
    this.accounts$ = store.pipe(selectAllEntities());
    this.selectedAccountId$ = store.pipe(map(state => state.selectedAccount));
    this.onboarding$ = store.pipe(map(state => state.onboarding));
    this.store = store;
  }

  addAccount(account: VasAccountDto) {
    this.store.update(addEntities(account));
  }

  deleteAccount(id: VasAccountDto['id']) {
    this.store.update(deleteEntities(id));
  }

  getAccount(id: VasAccountDto['id']): Observable<VasAccountDto> {
    return this.store.pipe(selectEntity(id));
  }

  setAccounts(accounts: VasAccountDto[]) {
    this.store.update(setEntities(accounts));
  }

  setSelectedAccountId(selectedAccount: string) {
    this.store.update(
      state => ({
        ...state,
        selectedAccount
      })
    );
  }

  clearAndSet(accounts: VasAccountDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {},
        selectedAccount: !state.selectedAccount && accounts.length === 1 ?
          accounts[0].id :
          state.selectedAccount
      }),
      setEntities(accounts)
    );
  }

  updateAccount(id: VasAccountDto['id'], account: Partial<VasAccountDto>) {
    this.store.update(updateEntities(id, account));
  }

  upsertAccounts(accounts: VasAccountDto[]): void {
    this.store.update(upsertEntities(accounts));
  }

  setOnboard(onboarding: boolean): void {
    this.store.update(state => ({
      ...state,
      onboarding
    }));
  }

  persist(): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    // eslint-disable-next-line no-underscore-dangle
    return this._persist('account');
  }

  private createStore(): typeof store {
    const store = createStore(
      {name: 'account'},
      withEntities<VasAccountDto>(),
      withProps<AccountRepositoryProps>({
        selectedAccount: undefined,
        onboarding: false
      })
    );

    return store;
  }
}
