import { VasCameraControlValueModel, VasJobDto } from '@ironcode/vas-lib';

export declare type SaveQueueItemStateType =
  'new'
  | 'initialising'
  | 'running'
  | 'paused'
  | 'success'
  | 'canceled'
  | 'error';

export enum SaveQueueItemState {
  NEW = 'new',
  INITIALISING = 'initialising',
  SUCCESS = 'success'
}

export interface SaveQueueItem {
  id: string;
  type: 'photo';
  jobId: string;
  item: VasJobDto | VasCameraControlValueModel;
  percent: number;
  created: string;
  state?: SaveQueueItemStateType;
  errorCode?: string;
  errorName?: string;
  errorMessage?: string;
}

export interface PhotoSaveQueueItem extends SaveQueueItem {
  type: 'photo';
  item: VasCameraControlValueModel;
  controlPath: string[];
}
