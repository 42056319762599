import { Injectable } from '@angular/core';
import { BaseDataService } from './base-data.service';
import { VasBranchDto } from '@ironcode/vas-lib';
import { Firestore, where } from '@angular/fire/firestore';
import { QueryConstraint } from '@firebase/firestore';
import { Observable, of } from 'rxjs';
import { AccountRepository } from '../state/account.repository';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BranchDataService extends BaseDataService<VasBranchDto> {

  protected accountIds: string[] = [];

  constructor(
    protected firestore: Firestore,
    public accountRepo: AccountRepository
  ) {
    super(firestore, 'branch');
    accountRepo.accounts$
      .pipe(map(accounts => accounts.map(account => account.id)))
      .subscribe(ids => this.accountIds = ids);
  }

  public get(
    queryFn: QueryConstraint[] = []
  ): Observable<Array<VasBranchDto>> {

    if (!this.accountIds.length) {
      return of([]);
    }

    return super.get([
      where('account', 'in', this.accountIds),
      ...queryFn,
    ]);
  }
}
