import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { App } from '@capacitor/app';
import { AppInfo } from '@capacitor/app/dist/esm/definitions';
import { BreadcrumbHint } from '@sentry/types/types/breadcrumb';

if (environment.production) {
  enableProdMode();
}

const matchHint = (hint: BreadcrumbHint): boolean => {
  if (!hint) {
    return false;
  }
  try {
    const url = hint?.xhr?.responseURL || '';
    return url.match(/firebasestorage.googleapis.com/i);
  } catch (e) {
  }
  return false;
};

const setup = async (): Promise<void> => {
  if (environment.production) {
    let info: AppInfo;
    try {
      info = await App.getInfo();
    } catch (e) {
    }
    Sentry.init({
      dsn: environment.sentry.dsn,
      release: info ? info.version : '0.0.0',
      environment: environment.production ? 'prod' : 'dev',
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false
        }),
        new BrowserTracing({
          tracePropagationTargets: ['localhost'],
          // see https://youtrack.ironcode.io/issue/VAS-434
          // tracePropagationTargets: ['localhost', 'api.vehicleassessorsystems.co.uk'],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      tracesSampleRate: 1,
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (matchHint(hint)) {
          try {
            breadcrumb.data.responseText = hint?.xhr?.responseText;
            return breadcrumb;
          } catch (e) {
            return breadcrumb;
          }
        }
        return breadcrumb;
      },
    });
  }

  await platformBrowserDynamic().bootstrapModule(AppModule);
  await defineCustomElements(window);
};

setup().catch(err => console.log(err));
