import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomePageModule)
  },
  {
    path: 'job-form',
    loadChildren: () => import('./pages/job-form-page/job-form-page.module')
      .then(m => m.JobFormPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login-page/login-page.module')
      .then(m => m.LoginPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings-page/settings-page.module')
      .then(m => m.SettingsPageModule)
  },
  {
    path: 'invitations',
    loadChildren: () => import('./pages/invitations-page/invitations-page.module')
      .then(m => m.InvitationsPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
