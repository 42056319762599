import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  TwitterAuthProvider,
  User
} from '@angular/fire/auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { setUserId } from '@firebase/analytics';
import { AuthCredential, UserCredential } from '@firebase/auth';
import { VasFireUserModel } from '@ironcode/vas-lib';
import { getAnalytics } from 'firebase/analytics';
import { of, ReplaySubject } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { UserDataService } from './user-data.service';
import { UtilService } from './util.service';
import * as Sentry from '@sentry/angular';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  readonly tag = '[AuthenticationService]';
  public currentUser: User | undefined;
  public currentUser$ = new ReplaySubject<User | undefined>(1);

  constructor(
    public fireAuth: Auth,
    public userDataServ: UserDataService,
    public util: UtilService,
    protected functions: Functions
  ) {
  }

  get displayName(): string {
    const displayNames = [
      this.currentUser.displayName,
      ...this.currentUser.providerData.map(_pd => _pd.displayName),
      this.emailToDisplayName(this.currentUser.email)
    ];
    return displayNames.find(pd => !!pd) || '';
  }

  init(): void {
    this.fireAuth.onAuthStateChanged(user => {
      this.setUser(user);
      this.logUserId(user?.uid);
    });
  }

  logUserId(id: string | undefined): void {
    setUserId(getAnalytics(), id);
  }

  forgotPassword(email: string) {
    sendPasswordResetEmail(this.fireAuth, email)
      .then(() => {
        return this.util.presentToast('Email Sent', true, 'bottom', 2100);
      })
      .catch(err => this.util.presentToast(`${err}`, true, 'bottom', 2100));
  }

  async signInAnonymously() {
    signInAnonymously(this.fireAuth)
      .then((userData) => {
        this.setUser(userData.user);
        return userData.user;
      });
  }

  public signupWithEmailPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.fireAuth, email, password)
      .then(res => {
        this.setUser(res.user);
        this.userDataServ.create(VasFireUserModel.newUser(
          res.user.uid, res.user.displayName, email
        ).toDto());
        return res;
      });
  }

  async loginWithEmailPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.fireAuth, email, password)
      .then(res => {
        this.setUser(res.user);
        return res;
      })
      .catch(error => {
        if (error.message.indexOf('auth/user-not-found') !== -1) {
          throw Error('invalid email');
        }
        throw Error('unknown error');
      });
  }

  async logout(): Promise<void> {
    console.debug(this.tag, 'logout');
    this.setUser(undefined);
    // mobile
    try {
      await GoogleAuth.signOut();
    } catch (e) {
    }
    // desktop
    try {
      await this.fireAuth.signOut();
    } catch (e) {
    }
  }

  public loginWithFacebook(accessToken) {
    const credential = FacebookAuthProvider
      .credential(accessToken);
    return signInWithCredential(this.fireAuth, credential);
  }

  public fbLogin(): Promise<any> {
    return signInWithPopup(this.fireAuth, new FacebookAuthProvider());
  }

  public loginWithTwitter(accessToken, accessSecret) {
    const credential = TwitterAuthProvider
      .credential(accessToken, accessSecret);
    return signInWithCredential(this.fireAuth, credential);
  }

  public twitterLogin(): Promise<any> {
    return signInWithPopup(this.fireAuth, new TwitterAuthProvider());
  }

  public signInWithGoogleProvider(
    accessToken: string,
    accessSecret?: string
  ) {
    // eslint-disable-next-line multiline-ternary
    const credential = accessSecret ? GoogleAuthProvider
      .credential(accessToken, accessSecret) : GoogleAuthProvider
      .credential(accessToken);
    return signInWithCredential(this.fireAuth, credential);
  }

  public signInWithCredential(
    credential: AuthCredential
  ): Promise<UserCredential> {
    return signInWithCredential(this.fireAuth, credential);
  }

  public googleLoginBrowser(): Promise<any> {
    return signInWithPopup(this.fireAuth, new GoogleAuthProvider());
  }

  public createUserFromProvider(user: User): void {
    this.setUser(user);
    this.userDataServ.create(VasFireUserModel.newUser(
      user.uid, user.displayName, user.email
    ).toDto());
  }

  async getIdToken(forceRefresh = false): Promise<string> {
    return this.currentUser$.asObservable().pipe(
      concatMap(user => user ? user.getIdToken(forceRefresh) : of(undefined))
    ).toPromise();
  }

  async removeUser(): Promise<void> {
    await httpsCallable(this.functions, 'userDelete')();
  }

  protected setUser(user: User | undefined): void {
    console.debug(this.tag, 'user', user);
    this.currentUser = user;
    this.currentUser$.next(user);

    if (user) {
      Sentry.setUser({
        email: user.email,
        id: user.uid
      });
    }
  }

  protected emailToDisplayName(email: string): string {
    const address = email.split('@').shift();
    const parts = address.split(/[^\w]/);
    return parts
      .map(part => part[0].toUpperCase() + part.substring(1))
      .join(' ');
  }
}
