import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { VasReportLayoutDto } from '@ironcode/vas-lib';
import { VasApi } from './vas/api/vas-api.service';

@Injectable({providedIn: 'root'})
export class ReportLayoutDataService {

  constructor(
    protected vasApi: VasApi
  ) {
  }

  getOne(
    accountId: string,
    id: string
  ): Observable<VasReportLayoutDto | undefined> {
    return this.getAll(accountId).pipe(
      map(list => list.find(layout => layout.id === id))
    );
  }

  getAll(
    accountId: string
  ): Observable<Array<VasReportLayoutDto>> {
    return this.vasApi.account.read().pipe(
      take(1),
      map(accounts => accounts.map(a => a.id)),
      concatMap(async accountIds => {
        let results = [];
        for (let id of accountIds) {
          let r = await this.vasApi.reportLayout.read(id).pipe(take(1)).toPromise();
          if (!r || !r.length) {
            continue;
          }
          results = results.concat(r);
        }
        return results;
      })
    )
  }
}
