import { AccountController } from './crud/account.controller';
import { FormController } from './crud/form.controller';
import { ControlTypeController } from './crud/control-type.controller';
import { ReportLayoutController } from './crud/report-layout.controller';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { StatusModel } from './models/status.model';
import { ContactController } from './crud/contact.controller';
import { JobStatusController } from './crud/job-status.controller';
import { InvitationController } from './crud/invitation.controller';

export interface VasLoginResponseBody {
  token: string | undefined;
  refresh_token: string | undefined;
}

/**
 * For connecting to the VAS api
 */
@Injectable()
export class VasApi {
  protected jwt: VasLoginResponseBody = {
    token: undefined,
    refresh_token: undefined
  };
  protected readonly apiVersion = 'v1';

  /**
   * @param {string} host
   * @param {Http} http
   */
  constructor(
    @Inject('VAS_API_HOST') protected host: string,
    protected http: HttpClient
  ) {
  }

  /**
   * Create the api route
   * @param {string} host
   * @param {string} apiVersion
   * @param {string} path
   * @return {string} the route
   */
  static r(
    host: string,
    apiVersion: string,
    path: string
  ): string {
    const url = new URL([
      host,
      '/api/',
      apiVersion,
      '/',
      path
    ].join(''));

    if (process.env.NG_APP_VAS_DEBUG) {
      url.searchParams.append('XDEBUG_SESSION_START', 'PHPSTORM');
    }

    return url.toString();
  }

  /**
   * @return {AccountController}
   */
  get account(): AccountController {
    return new AccountController(this.host, this.apiVersion, this.http);
  }

  get contact(): ContactController {
    return new ContactController(this.host, this.apiVersion, this.http);
  }

  /**
   * @return {ControlTypeController}
   */
  get controlType(): ControlTypeController {
    return new ControlTypeController(this.host, this.apiVersion, this.http);
  }

  /**
   * @return InvitationController
   */
  get invitation(): InvitationController {
    return new InvitationController(this.host, this.apiVersion, this.http);
  }

  get jobStatus(): JobStatusController {
    return new JobStatusController(this.host, this.apiVersion, this.http);
  }

  /**
   * @return {AccountController}
   */
  get form(): FormController {
    return new FormController(this.host, this.apiVersion, this.http);
  }

  /**
   * @return {ReportLayoutController}
   */
  get reportLayout(): ReportLayoutController {
    return new ReportLayoutController(this.host, this.apiVersion, this.http);
  }

  /**
   * Login to the API
   * @param {string} username
   * @param {string} password
   * @return {Observable<VasLoginResponseBody>}
   */
  login(
    username: string,
    password: string
  ): Observable<VasLoginResponseBody> {
    return this.http.post(this.r('login'), {
      username,
      password
    }).pipe(
      map(response => response as VasLoginResponseBody),
      tap(jwt => {
        if (!jwt || !jwt.token) {
          throw Error('invalid response');
        }
        this.jwt = jwt as VasLoginResponseBody;
      })
    );
  }

  /**
   * Create the api route
   * @param {string} path
   * @return {string} the route
   */
  r(path: string): string {
    return VasApi.r(
      this.host,
      this.apiVersion,
      path
    );
  }

  getStatus(): Observable<StatusModel> {
    return this.http.get<StatusModel>(this.r('status'));
  }
}
