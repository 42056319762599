import { Observable, Subject, throwError } from 'rxjs';
import { VasBaseDto } from '@ironcode/vas-lib';
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocFromCache,
  onSnapshot,
  query,
  setDoc,
  updateDoc
} from '@angular/fire/firestore';
import { catchError, finalize, map } from 'rxjs/operators';
import {
  CollectionReference,
  DocumentData,
  FieldPath,
  FirestoreDataConverter,
  QueryConstraint,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue
} from '@firebase/firestore';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export abstract class BaseDataService<T extends VasBaseDto & DocumentData> {

  converter: FirestoreDataConverter<T> = {
    fromFirestore(
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options?: SnapshotOptions
    ): T {
      return snapshot.data() as T;
    },
    toFirestore(modelObject: WithFieldValue<T>): DocumentData {
      return modelObject as DocumentData;
    }
  };

  protected collectionRef: CollectionReference<DocumentData>;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    protected firestore: Firestore,
    protected collectionName: string
  ) {
    if (!collectionName) {
      throw new Error('collectionName is required');
    }

    this.collectionRef = collection(this.firestore, collectionName)
      .withConverter(this.converter);
  }

  /**
   * Create a document. This method intentionally ignore the Promise returned
   * from updateDoc because that Promise will not resolve in offline mode.
   * @return {Promise<any>}
   */
  public create(
    data: T
  ): void {
    const d = doc(this.collectionRef, data.id);
    setDoc(d, data);
  }

  /**
   * Delete a document. This method intentionally ignore the Promise returned
   * from updateDoc because that Promise will not resolve in offline mode.
   * @param {string} id
   * @return {Promise<any>}
   */
  public delete(
    id: string
  ): void {
    const d = doc(this.collectionRef, id);
    deleteDoc(d);
  }

  /**
   * Returns an observable of results for the query. Note the stream will
   * never terminate, so you must do this yourself.
   *
   * @param queryFn list of constraints
   * @return observable of results
   */
  public get(
    queryFn: QueryConstraint[] = []
  ): Observable<Array<T>> {
    const q = query(this.collectionRef, ...queryFn);
    const subject = new Subject<T[]>();
    const s = onSnapshot(
      q,
      snapshot => {
        const items: Array<T> = [];
        snapshot.docs.forEach(_doc => items.push(_doc.data() as any));
        subject.next(items);
      },
      error => {
        subject.error(new Error(
          `firestore error in collection ${this.collectionName}. ` +
          `${error.code}: ${error.message}`
        ));
      }
    );

    return subject.pipe(
      // when the subscription ends, we kill the snapshot listener
      finalize(() => s())
    );
  }

  public getOne(
    id: string
  ): Observable<T> {
    const d = doc(this.collectionRef, id);
    return fromPromise(getDoc(d)).pipe(
      map(snapshot => snapshot.data() as T),
      catchError(error => {
        const newError = new Error(
          `firestore error in collection ${this.collectionName}. ` +
          `${error.code}: ${error.message}`
        );
        console.error(newError);
        return throwError(newError);
      })
    );
  }

  public getOneCache(
    id: string
  ): Observable<T> {
    const d = doc(this.collectionRef, id);
    return fromPromise(getDocFromCache(d)).pipe(
      map(snapshot => snapshot.data() as T)
    );
  }

  /**
   * Update a document. This method intentionally ignores the Promise returned
   * from updateDoc because that Promise will not resolve in offline mode.
   * @param {string} id
   * @param {T} data
   */
  public update(
    id: string,
    data: Partial<T> & DocumentData
  ): void {
    const d = doc(this.collectionRef, id);
    updateDoc(d, data);
  }

  public updateFieldPath(
    id: string,
    fieldPath: Array<string>,
    data: object | string | number | boolean
  ): void {
    const d = doc(this.collectionRef, id);
    const fp = new FieldPath(...fieldPath);
    updateDoc(d, fp, data);
  }
}
