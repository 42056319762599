import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PersistableRepository } from './persistable.repository';
import { StateStorageService } from './state-storage.service';
import { VasJobDto } from '@ironcode/vas-lib';
import { AccountRepository } from './account.repository';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobRepository extends PersistableRepository {
  job$: Observable<VasJobDto[]>;

  protected store;

  constructor(
    protected stateStorageService: StateStorageService,
    protected accountRepo: AccountRepository
  ) {
    super(stateStorageService);
    const store = this.createStore();
    this.job$ = combineLatest([
      store.pipe(selectAllEntities()),
      this.accountRepo.selectedAccountId$
    ]).pipe(
      map(([items, selectedAccountId]) => items
        .filter(item => item.account === selectedAccountId)
      )
    );
    this.store = store;
  }

  addJob(job: VasJobDto) {
    this.store.update(addEntities(job));
  }

  addJobs(jobs: VasJobDto[]) {
    this.store.update(addEntities(jobs));
  }

  deleteJob(id: VasJobDto['id']) {
    this.store.update(deleteEntities(id));
  }

  clearAndSetJobs(jobs: VasJobDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {}
      }),
      setEntities(jobs)
    );
  }

  getJob(id: VasJobDto['id']): Observable<VasJobDto> {
    return this.store.pipe(selectEntity(id));
  }

  setJobs(jobs: VasJobDto[]) {
    this.store.update(setEntities(jobs));
  }

  updateJob(id: VasJobDto['id'], job: Partial<VasJobDto>) {
    this.store.update(updateEntities(id, job));
  }

  upsertJobs(jobs: VasJobDto[]): void {
    this.store.update(upsertEntities(jobs));
  }

  persist(): {initialized$: Observable<boolean>; unsubscribe(): void} {
    return this._persist('job');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'job'}, withEntities<VasJobDto>());

    return store;
  }
}
