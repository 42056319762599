import { Injectable } from '@angular/core';
import { BaseDataService } from './base-data.service';
import { PaginationProps } from '../models/pagination-props';
import { VasJobDto } from '@ironcode/vas-lib';
import { QueryConstraint } from '@firebase/firestore';
import { Firestore, limit, orderBy, startAfter, where } from '@angular/fire/firestore';
import { AuthenticationService } from './firebase-authentication.service';

export interface JobDataServiceFilters {
  jobStatusId?: string;
  onlyMine?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class JobDataService extends BaseDataService<VasJobDto> {

  constructor(
    protected firestore: Firestore,
    protected authService: AuthenticationService
  ) {
    super(firestore, 'job');
  }

  static makeQueryFn(
    pagination: PaginationProps<VasJobDto>,
    uid: string,
    accountId: string,
    filters: JobDataServiceFilters = {},
    isAdmin = false
  ): QueryConstraint[] {

    const c = [];

    if (filters.jobStatusId) {
      c.push(where('jobStatus', '==', filters.jobStatusId));
    }

    if (!isAdmin || (isAdmin && filters.onlyMine)) {
      c.push(where('createdBy', '==', uid));
    }

    c.push(where('account', '==', accountId));
    c.push(orderBy(pagination.orderBy, pagination.orderDirection));
    c.push(orderBy('id', 'asc'));
    c.push(limit(pagination.limit));

    if (pagination.offset) {
      c.push(startAfter(
        pagination.offset[pagination.orderBy],
        'id'
      ));
    }
    return c;
  }

  create(data: VasJobDto): void {
    data.createdByDisplayName = this.authService.displayName || '';
    super.create(data);
  }

  update(id: string, data: Partial<VasJobDto>): void {
    data.modifiedByDisplayName = this.authService.displayName || '';
    super.update(id, data);
  }
}
