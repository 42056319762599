import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PersistableRepository } from './persistable.repository';
import { StateStorageService } from './state-storage.service';
import { VasControlTypeDto } from '@ironcode/vas-lib';

@Injectable({
  providedIn: 'root'
})
export class ControlTypeRepository extends PersistableRepository {
  controlTypes$: Observable<VasControlTypeDto[]>;

  protected store;

  constructor(
    protected stateStorageService: StateStorageService
  ) {
    super(stateStorageService);
    this.store = this.createStore();
    this.controlTypes$ = this.store.pipe(selectAllEntities());
  }

  addControlType(controlType: VasControlTypeDto) {
    this.store.update(addEntities(controlType));
  }

  deleteControlType(id: VasControlTypeDto['id']) {
    this.store.update(deleteEntities(id));
  }

  getAll(): Observable<VasControlTypeDto[]> {
    return this.store.pipe(selectAllEntities());
  }

  setControlTypes(controlTypes: VasControlTypeDto[]) {
    this.store.update(setEntities(controlTypes));
  }

  clearAndSet(controlTypes: VasControlTypeDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {}
      }),
      setEntities(controlTypes)
    );
  }

  updateControlType(id: VasControlTypeDto['id'], controlType: Partial<VasControlTypeDto>) {
    this.store.update(updateEntities(id, controlType));
  }

  upsertControlTypes(controlTypes: VasControlTypeDto[]): void {
    this.store.update(upsertEntities(controlTypes));
  }

  persist(): {initialized$: Observable<boolean>; unsubscribe(): void} {
    return this._persist('control-types');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'control-type'}, withEntities<VasControlTypeDto>());

    return store;
  }
}
