import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { provideAnalytics } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, indexedDBLocalPersistence, provideAuth } from '@angular/fire/auth';
import { initializeAuth } from 'firebase/auth/cordova';
import {
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  getFirestore,
  provideFirestore
} from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { initializeAnalytics } from '@firebase/analytics';
import { initializeFirestore } from '@firebase/firestore';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { appInitialise } from './app-initialise';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageRepository } from './pages/home/home.page.repository';
import { AuthenticationService } from './services/firebase-authentication.service';
import { FormStateService } from './services/form-state.service';
import { GoogleAnalyticsPageViewService } from './services/google-analytics-page-view.service';
import { JwtTokenProviderService } from './services/jwt-token-provider.service';
import { MasterDataService } from './services/master-data.service';
import { UploadQueueService } from './services/upload-queue.service';
import { VasApi } from './services/vas/api/vas-api.service';
import { VAS_JWT_TOKEN_PROVIDER, } from './services/vas/api/vas-jwt-token-provider.service';
import { VasJwtInterceptor } from './services/vas/api/vas-jwt.interceptor';
import { AccountRepository } from './state/account.repository';
import { BranchRepository } from './state/branch.repository';
import { ContactRepository } from './state/contact.repository';
import { ControlTypeRepository } from './state/control-type.repository';
import { FormRepository } from './state/form.repository';
import { JobStatusRepository } from './state/job-status.repository';
import { JobRepository } from './state/job.repository';
import { ReportLayoutRepository } from './state/report-layout.repository';
import { SaveQueueRepository } from './state/save-queue-repository.service';
import { StateStorageService } from './state/state-storage.service';
import { PermsService } from './services/perms.service';

const VAS_API_HOST = new InjectionToken('VAS_API_HOST');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      if (environment.firebase.emulate) {
        initializeFirestore(getApp(), {
          experimentalForceLongPolling: true
        });
      }
      const firestore = getFirestore();
      if (environment.firebase.emulate) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.firebase.emulate) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
    provideAuth(() => {
      // this logic changes how we initialise the firebase auth resources,
      // if we are running in iOS context we should use the local files
      // otherwise we'll get all kinds of errors. Normally we would have
      // injected the platform service here to detect which platform we're
      // running in, however app.module is too early in the process and I can't
      // find another way to get, so using an environment variable to control it
      const useLocal = process.env.NG_APP_USE_LOCAL_AUTH === 'true';
      if (useLocal) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        return getAuth();
      }
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.firebase.emulate) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
    provideAnalytics(() => {
      const analytics = initializeAnalytics(getApp(), {
        config: {
          send_page_view: false,
          debug_mode: environment.debugGA
        }
      });
      return analytics;
    }),
    HttpClientModule,
    RouterModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: VAS_JWT_TOKEN_PROVIDER, useClass: JwtTokenProviderService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VasJwtInterceptor,
      multi: true,
      deps: [VAS_JWT_TOKEN_PROVIDER]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialise,
      deps: [
        ErrorHandler,
        AuthenticationService,
        UploadQueueService,
        StateStorageService,
        AccountRepository,
        FormRepository,
        ControlTypeRepository,
        JobRepository,
        ReportLayoutRepository,
        BranchRepository,
        JobStatusRepository,
        ContactRepository,
        HomePageRepository,
        SaveQueueRepository,
        MasterDataService,
        GoogleAnalyticsPageViewService,
        FormStateService,
        PermsService,
        Router
      ],
      multi: true
    },
    {
      provide: VAS_API_HOST,
      useValue: environment.vas.host
    },
    {
      provide: VasApi,
      useClass: VasApi,
      deps: [VAS_API_HOST, HttpClient]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
