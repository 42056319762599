import { VasJwtTokenProviderService } from './vas/api/vas-jwt-token-provider.service';
import { Injectable } from '@angular/core';
import { getAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenProviderService extends VasJwtTokenProviderService {

  getToken(): Promise<string> {
    const user = getAuth().currentUser;
    return user ? user.getIdToken() : undefined;
  }
}
