export const environment = {
  production: false,
  localForage: {
    name: 'pen2click',
    version: 1.0,
    storeName: 'state'
  },
  vas: {
    host: process.env.NG_APP_VAS_HOST
  },
  debugGA: true,
  firebase: {
    emulate: process.env.NG_APP_FIREBASE_EMULATE === 'true',
    apiKey: 'AIzaSyB82v3RqV80WHgYISbSbDcK88t_EH61RWo',
    authDomain: 'pen2click-349109.firebaseapp.com',
    projectId: 'pen2click-349109',
    storageBucket: 'pen2click-349109.appspot.com',
    messagingSenderId: '821256499723',
    appId: '1:821256499723:web:89b3211fe4bd3ac3304d0e',
    measurementId: 'G-8GFNYY6DWN'
  },
  sentry: {
    dsn: 'https://cb98dd2a0c394ec0bfd8716ebe906fc3@o463862.ingest.sentry.io/4504509881188352'
  },
  appleConnect: {
    clientId: 'com.pen2click.app-service',
    redirectURI: 'https://pen2click-349109.firebaseapp.com/__/auth/handler',
    scopes: 'email name',
    state: '12345',
    nonce: 'nonce',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
