import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { VasApi } from './vas-api.service';
import { VasJwtTokenProviderService } from './vas-jwt-token-provider.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class VasJwtInterceptor implements HttpInterceptor {

  constructor(
    @Inject('VAS_JWT_TOKEN_PROVIDER') protected tokenProvider: VasJwtTokenProviderService
  ) {
  }

  /**
   * This method intercepts requests that are targeted at the Devon API, and
   * adds the `idToken` which we received from Auth0
   *
   * @param req {HttpRequest<any>}
   * @param next {HttpHandler}
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // ignore requests not for VAS API
    if (!req.url.startsWith(environment.vas.host)) {
      return next.handle(req);
    }

    // ignore requests for VAS API login
    if (req.url === VasApi.r(environment.vas.host, 'v1', 'login')) {
      return next.handle(req);
    }

    // ignore requests for VAS API status
    if (req.url === VasApi.r(environment.vas.host, 'v1', 'status')) {
      return next.handle(req);
    }

    return this.handle(req, next);
  }

  handle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {

    return fromPromise(this.tokenProvider.getToken()).pipe(
      concatMap(token => {
        if (!token) {
          return next.handle(req);
        }
        req = req.clone({
          headers: req.headers.set(
            'Authorization', `Bearer ${token}`,
          )
        });
        return next.handle(req);
      })
    );
  }
}
