import { Injectable } from '@angular/core';
import { StateStorage } from '@ngneat/elf-persist-state/lib/storage';
import { Observable } from 'rxjs';
import 'localforage';

declare type MaybeAsync<T> = Promise<T> | Observable<T>;

@Injectable({
  providedIn: 'root'
})
export class StateStorageService implements StateStorage {

  public storage: LocalForage;

  getItem<T extends Record<string, any>>(
    key: string
  ): MaybeAsync<T | undefined> {
    return this.storage.getItem(key);
  }

  removeItem(
    key: string
  ): MaybeAsync<boolean> {
    return this.storage.removeItem(key).then(() => true, () => false);
  }

  setItem(
    key: string,
    value: Record<string, any>
  ): MaybeAsync<boolean> {
    return this.storage.setItem(key, value).then(() => true, () => false);
  }
}
