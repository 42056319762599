import { Observable, of, throwError } from 'rxjs';
import { BaseCrudController } from './base-crud.controller';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { VasAccountDto, VasAccountObjectDto } from '@ironcode/vas-lib';
import { catchError } from 'rxjs/operators';

/**
 * AccountController
 */
export class AccountController extends BaseCrudController {
  /**
   * @param {string} host
   * @param {string} apiVersion
   * @param {HttpClient} http
   */
  constructor(
    protected host: string,
    protected apiVersion: string,
    protected http: HttpClient
  ) {
    super(host, apiVersion, http, 'accounts');
  }

  /**
   * @inheritDoc
   */
  create(): Observable<HttpResponse<void>> {
    throw Error('not implemented');
  }

  /**
   * @inheritDoc
   */
  delete(): Observable<HttpResponse<void>> {
    throw Error('not implemented');
  }

  /**
   * @inheritDoc
   */
  read(): Observable<Array<VasAccountDto>> {
    return this.http.get<Array<VasAccountDto>>(this.r(this.entityName)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        }
        return throwError(error);
      })
    );
  }

  /**
   * @inheritDoc
   */
  readOne(id: string): Observable<VasAccountDto> {
    return this.http.get<VasAccountDto>(
      this.r(`${this.entityName}/${id}`)
    );
  }

  /**
   * @inheritDoc
   */
  update(
    id: string,
    object: Partial<VasAccountObjectDto>
  ): Observable<Object> {
    return this.http.patch(
      this.r(`${this.entityName}/${id}`),
      {
        account: object
      }
    );
  }
}
