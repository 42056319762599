import { Injectable } from '@angular/core';
import { BaseDataService } from './base-data.service';
import { VasFireUserDto } from '@ironcode/vas-lib';
import { Firestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserDataService extends BaseDataService<VasFireUserDto> {

  constructor(
    protected firestore: Firestore
  ) {
    super(firestore, 'users');
  }

  isAdmin(uid, accountId): Observable<boolean> {
    return this.getOne(uid).pipe(
      map(user => user.accounts ?
        user.accounts[accountId]?.membership?.admin === true :
        false
      )
    );
  }
}
