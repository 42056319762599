import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsPageViewService {

  protected baseTitle = 'Pen2Click';

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected title: Title
  ) {
  }

  watchNavigation(): void {

    const analytics = getAnalytics();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      switchMap(route => route.data),
      map((data) => {
        const title: string = data?.title || this.baseTitle;
        const firebaseScreen: string = data?.firebaseScreen || '';
        logEvent(analytics, 'page_view', {
          page_title: title,
          firebase_screen: firebaseScreen,
          page_path: this.router.url,
          page_location: location.host + this.router.url
        });

        return data && data.title ?
          `${this.baseTitle} - ${data.title}` :
          this.baseTitle;
      })
    ).subscribe((current_title) => this.title.setTitle(current_title));
  }
}
