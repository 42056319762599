import { ErrorHandler, Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { VasCameraControlValueModel } from '@ironcode/vas-lib';
import { BackgroundFetch } from '@transistorsoft/capacitor-background-fetch';
import { BackgroundFetchStatus } from '@transistorsoft/capacitor-background-fetch/dist/esm/definitions';
import { UUID } from 'angular2-uuid';
import moment from 'moment';
import { Observable, of, Subject, timer } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { PhotoSaveQueueItem } from '../models/vas/firebase/save-queue-item.model';
import { SaveQueueRepository } from '../state/save-queue-repository.service';
import { PhotoUploadService } from './uploaders/photo-upload.service';
import { UtilService } from './util.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class UploadQueueService {

  readonly SYNC_UP_INTERVAL_DELAY = 10 * 1000;
  running = false;
  errors$ = new Subject<Error>();
  protected backgroundFetchStatus: BackgroundFetchStatus;
  protected stopSyncUpInterval$: Subject<string> = new Subject();
  protected syncUpInterval$: Observable<void> = timer(
    0,
    this.SYNC_UP_INTERVAL_DELAY
  ).pipe(
    takeUntil(this.stopSyncUpInterval$),
    filter(() => !this.running),
    switchMap(() => this.uploadItems()),
    catchError(error => {
      this.utilService.presentToast(error.message);
      this.errorHandler.handleError(error);
      return of(undefined);
    })
  );

  constructor(
    public saveQueueRepo: SaveQueueRepository,
    protected photoUploadService: PhotoUploadService,
    protected utilService: UtilService,
    protected errorHandler: ErrorHandler
  ) {

  }

  /**
   * Add a CameraControlValue to the upload queue
   *
   * @param photo {VasCameraControlValueModel} the control's value which comes from
   * {Photo}
   * @param jobId {string} the id of the job this control is associated with
   * @param controlPath {string[]} path segments for the control in the job
   * @return the id of the save queue item
   */
  addCameraControlValue(
    photo: VasCameraControlValueModel,
    jobId: string,
    controlPath: string[]
  ): string {

    const id = UUID.UUID();

    this.saveQueueRepo.addPhotoSaveQueueItem({
      id,
      type: 'photo',
      item: photo,
      percent: 0,
      jobId,
      controlPath,
      created: moment().toISOString()
    });
    return id;
  }

  async initBackgroundFetch() {
    this.backgroundFetchStatus = await BackgroundFetch.configure({
      minimumFetchInterval: 15
    }, async (taskId) => {
      console.log('[BackgroundFetch] EVENT:', taskId);
      // Perform your work in an awaited Promise
      const result = await this.uploadItems();
      console.debug('[BackgroundFetch] work complete:', result);
      // [REQUIRED] Signal to the OS that your work is complete.
      return BackgroundFetch.finish(taskId);
    }, async (taskId) => {
      // The OS has signalled that your remaining background-time has expired.
      // You must immediately complete your work and signal #finish.
      console.debug('[BackgroundFetch] TIMEOUT:', taskId);
      // [REQUIRED] Signal to the OS that your work is complete.
      return BackgroundFetch.finish(taskId);
    });
  }

  logBackgroundFetchStatus(): void {
    if (this.backgroundFetchStatus !== BackgroundFetch.STATUS_AVAILABLE) {
      if (this.backgroundFetchStatus === BackgroundFetch.STATUS_DENIED) {
        console.log(
          'The user explicitly disabled background behavior for this app or for the whole system.'
        );
      } else if (this.backgroundFetchStatus === BackgroundFetch.STATUS_RESTRICTED) {
        console.log(
          'Background updates are unavailable and the user cannot enable them again.'
        );
      }
    }
  }

  startUploadInterval(): void {
    this.stopUploadInterval();
    this.syncUpInterval$.subscribe();
  }

  stopUploadInterval(): void {
    this.stopSyncUpInterval$.next(undefined);
  }

  /**
   * This method will check for any upload items that have got stuck. This can
   * happen if the app is interrupted during the upload process. In some cases
   * the image will have already been uploaded but the app failed to update the
   * save queue, in other cases the image may not be uploaded yet, in which case
   * we need to re-upload. This method should be called during the app
   * initialisation process when we know the user has the app open, and the OS
   * should not interrupt our work.
   * @return {Promise<any>}
   */
  async houseKeeping(): Promise<void> {

    return new Promise(async (resolve, reject) => {
      const allItems = await this.saveQueueRepo.saveQueue$
        .pipe(take(1))
        .toPromise();

      const stuckItems = allItems.filter(i => i.state !== 'new');

      if (!stuckItems.length) {
        resolve();
        return;
      }

      const alert = await this.utilService.alertController.create({
        subHeader: 'Unfinished uploads found',
        message: 'Press Ok to finish them now',
        buttons: [
          {
            text: 'Ok',
            handler: async () => {
              const loader = await this.utilService.loadingController.create();
              await loader.present();
              for (let i = 0; i < stuckItems.length; i++) {
                loader.message = `${i + 1}/${stuckItems.length}`;
                await this.photoUploadService
                  .uploadItem(stuckItems[i] as PhotoSaveQueueItem)
                  .pipe(take(1))
                  .toPromise()
                  .catch(error => {
                    this.saveQueueRepo.deleteSaveQueueItem(stuckItems[i].id);
                    this.utilService.presentToast(error.message);
                    resolve();
                  });
              }
              await loader.dismiss();
              resolve();
            }
          },
          {
            text: 'Cancel',
            handler: () => resolve()
          }
        ]
      });
      await alert.present();
    });
  }

  /**
   * Main entry point for starting the upload of job and file data to firebase
   * The method checks the network status and if network is not available, will
   * return.
   */
  async uploadItems(id?: string): Promise<void> {

    if (this.running) {
      return;
    }

    this.running = true;

    for (let i = 0; i < 100; i++) {
      let item;
      if (id) {
        item = await this.saveQueueRepo.getItemById(id)
          .pipe(take(1))
          .toPromise();
      } else {
        item = await this.saveQueueRepo.getNextItemByState('new')
          .pipe(take(1))
          .toPromise()
          .catch(error => {
            this.utilService.presentToast(error.message);
            this.running = false;
          });
      }

      if (!item) {
        break;
      }

      const networkStatus = await Network.getStatus();

      if (!networkStatus.connected) {
        this.running = false;
        return;
      }

      switch (item.type) {
        case 'photo': {
          try {
            Sentry.addBreadcrumb({
              category: 'photo-upload',
              message: 'initialising',
              data: item,
              level: 'debug',
            });
          } catch (e) {
            console.warn(e);
          }

          await this.photoUploadService.uploadItem(item as PhotoSaveQueueItem)
            .pipe(take(1))
            .toPromise()
            .catch(error => {
              this.errorHandler.handleError(error);
            });
          break;
        }
        default: {
          console.error(Error(`no upload service for type '${item.type}'`));
        }
      }
    }
    this.running = false;
  }
}
