import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  deleteEntities,
  selectAllEntities,
  updateEntities,
  withEntities
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PhotoSaveQueueItem,
  SaveQueueItem,
  SaveQueueItemStateType
} from '../models/vas/firebase/save-queue-item.model';
import { PersistableRepository } from './persistable.repository';
import { StateStorageService } from './state-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SaveQueueRepository extends PersistableRepository {
  saveQueue$: Observable<SaveQueueItem[]>;
  saveQueueSorted$: Observable<SaveQueueItem[]>;
  saveQueuePhotos$: Observable<PhotoSaveQueueItem[]>;

  protected store;

  constructor(
    stateStorageService: StateStorageService
  ) {
    super(stateStorageService);
    this.store = this.createStore();
    this.saveQueue$ = this.store.pipe(selectAllEntities());

    this.saveQueueSorted$ = this.saveQueue$.pipe(
      map(items => items
        .map(item => ({
          ...item,
          _state: this.stateToIndex(item.state)
        }))
        .sort((a, b) =>
          a._state - b._state ||
          a.type.localeCompare(b.type) ||
          a.created.localeCompare(b.created))
      )
    );

    this.saveQueuePhotos$ = this.saveQueue$.pipe(
      map(items => items
        .filter(item => item.type === 'photo')
      )
    ) as Observable<PhotoSaveQueueItem[]>;
  }

  protected stateToIndex(state: SaveQueueItemStateType): number {
    switch (state) {
      case 'new':
        return 0;
      case 'initialising':
        return 1;
      case 'running':
        return 2;
      case 'success':
        return 3;
      case 'paused':
        return 300;
      case 'canceled':
        return 400;
      case 'error':
        return 500;
    }
  }

  addPhotoSaveQueueItem(photoSaveQueueItem: PhotoSaveQueueItem) {
    this.store.update(addEntities({
      ...photoSaveQueueItem,
      state: 'new'
    }));
  }

  addSaveQueueItem(item: SaveQueueItem) {
    this.store.update(addEntities(item));
  }

  clear(): void {
    this.store.update(deleteAllEntities())
  }

  deleteSaveQueueItem(id: SaveQueueItem['id']) {
    this.store.update(deleteEntities(id));
  }

  getItemById(id: string): Observable<SaveQueueItem | undefined> {
    return this.saveQueue$.pipe(
      map(items => items.find(item => item.id === id))
    )
  }

  getNextItemByState(state: SaveQueueItemStateType): Observable<SaveQueueItem> {
    return this.saveQueue$.pipe(
      map(items => items
        .filter(item => item.state === state)
        .sort((a, b) =>
          // photos after jobs            // created descending
          a.type.localeCompare(b.type) || a.created.localeCompare(b.created))),
      map(items => items.pop())
    );
  }

  updateSaveQueue(id: SaveQueueItem['id'], jobSaveQueue: Partial<SaveQueueItem>) {
    this.store.update(updateEntities(id, jobSaveQueue));
  }

  setError(id: string, error: Error): void {
    this.updateSaveQueue(id, {
      errorCode: (error as any).code,
      errorMessage: error.message,
      errorName: error.name
    });
  }

  setPercentage(id: string, percentage: number): void {
    this.store.update(updateEntities(id, {percentage}));
  }

  setState(id: string, state: SaveQueueItemStateType): void {
    this.store.update(updateEntities(id, {state}));
  }

  persist(): {initialized$: Observable<boolean>; unsubscribe(): void} {
    return this._persist('saveQueue');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'jobSaveQueue'}, withEntities<SaveQueueItem>());

    return store;
  }
}
