import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateStorageService } from './state-storage.service';
import { PersistableRepository } from './persistable.repository';
import { VasAccountDto, VasJobStatusDto } from '@ironcode/vas-lib';
import { map } from 'rxjs/operators';
import { AccountRepository } from './account.repository';
import { Store } from '@ngneat/elf/lib/store';

@Injectable({
  providedIn: 'root'
})
export class JobStatusRepository extends PersistableRepository {

  jobStatuses$: Observable<VasJobStatusDto[]>;
  store: Store<{
    name: string;
    state: {
      ids: Array<VasAccountDto['id']>,
      entities: Record<VasJobStatusDto['id'], VasJobStatusDto>
    };
    config: any;
  }>;

  constructor(
    protected stateStorageService: StateStorageService,
    protected accountRepo: AccountRepository
  ) {
    super(stateStorageService);
    const store = this.createStore();

    this.jobStatuses$ = combineLatest([
      store.pipe(selectAllEntities()),
      this.accountRepo.selectedAccountId$
    ]).pipe(
      map(([items, accountId]) => items.filter(js => js.account === accountId))
    );

    this.store = store;
  }

  addJobStatus(jobStatus: VasJobStatusDto) {
    this.store.update(addEntities(jobStatus));
  }

  deleteJobStatus(id: VasJobStatusDto['id']) {
    this.store.update(deleteEntities(id));
  }

  getDefaultJobStatus(): string | undefined {
    const accountId = this.accountRepo.store.state.selectedAccount;
    return Object
      .values(this.store.state.entities)
      .filter(item => item.account === accountId)
      .sort((a, b) => b.sequence - a.sequence)
      .pop()?.id;
  }

  getJobStatus(id: VasJobStatusDto['id']): Observable<VasJobStatusDto> {
    return this.store.pipe(selectEntity(id));
  }

  setJobStatuses(jobStatuses: VasJobStatusDto[]) {
    this.store.update(setEntities(jobStatuses));
  }

  clearAndSet(jobStatuses: VasJobStatusDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {}
      }),
      setEntities(jobStatuses)
    );
  }

  updateJobStatus(id: VasJobStatusDto['id'], jobStatus: Partial<VasJobStatusDto>) {
    this.store.update(updateEntities(id, jobStatus));
  }

  upsertJobStatuses(jobStatuses: VasJobStatusDto[]): void {
    this.store.update(upsertEntities(jobStatuses));
  }

  persist(): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    return this._persist('jobStatus');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'jobStatus'}, withEntities<VasJobStatusDto>());

    return store;
  }
}
