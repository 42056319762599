import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getDownloadURL, ref, Storage, uploadBytesResumable, UploadTask } from '@angular/fire/storage';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root'
})
export class FileStorageService {
  constructor(
    public storage: Storage
  ) {
  }

  /**
   * Uploads blob content to Firebase Storage (appspot) under the uploads
   * folder and returns a public url to download the content
   *
   * @param data {Blob} the content to be uploaded
   * @param fileName {string} the file name, usually a guid
   * @param path {string} the path where the data should be uploaded
   * @return UploadTask a task to monitor the upload progress
   */
  uploadContent(
    data: Blob,
    fileName: string,
    path: string = 'uploads'
  ): UploadTask {
    const r = ref(this.storage, `${path}/${fileName}`);
    return uploadBytesResumable(r, data);
  }

  /**
   * Returns a public url through which the file content can be downloaded
   *
   * @param fileName {string} the file name, usually a guid
   * @param path {string} the path where the data file is stored
   * @return downloadUrl the url
   */
  getFileDownloadUrl(
    fileName: string,
    path: string = 'uploads'
  ): Observable<string> {
    const r = ref(this.storage, `${path}/${fileName}`);
    return fromPromise(getDownloadURL(r));
  }
}

