import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VasControlTypeDto } from '@ironcode/vas-lib';
import { VasApi } from './vas/api/vas-api.service';

@Injectable({
  providedIn: 'root'
})
export class ControlTypeDataService {

  constructor(
    protected vasApi: VasApi
  ) {
  }

  getAll(): Observable<Array<VasControlTypeDto>> {
    return this.vasApi.controlType.read();
  }
}
