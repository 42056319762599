import { AuthenticationService } from './firebase-authentication.service';
import { UserDataService } from './user-data.service';
import { AccountRepository } from '../state/account.repository';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { concatMap, distinctUntilChanged, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermsService {
  readonly tag = '[PermsService]';
  isAdmin = false;
  protected isAdminInternal$ = new BehaviorSubject(false);

  constructor(
    protected authService: AuthenticationService,
    protected accountRepo: AccountRepository,
    protected userDataService: UserDataService,
  ) {
  }

  get isAdmin$(): Observable<boolean> {
    return this.isAdminInternal$.asObservable();
  }

  init(): void {
    console.debug(this.tag, 'init');
    combineLatest([
      this.authService.currentUser$.pipe(
        distinctUntilChanged(),
        tap(value => console.debug(this.tag, 'user changed to', value))
      ),
      this.accountRepo.selectedAccountId$.pipe(
        distinctUntilChanged(),
        tap(value => console.debug(this.tag, 'account changed to', value)))
    ]).pipe(
      concatMap(
        ([user, accountId]) => {
          return user ?
            this.userDataService.isAdmin(user.uid, accountId) :
            of(false);
        }
      ),
      tap(isAdmin => console.debug(this.tag, 'emitting isAdmin', isAdmin)),
    ).subscribe(isAdmin => this.isAdminInternal$.next(isAdmin));
  }

  async refresh(): Promise<void> {
    console.debug(this.tag, 'refresh');
    const user = this.authService.currentUser;
    const accountId = this.accountRepo.store.state.selectedAccount;

    if (!user || !accountId) {
      console.debug(this.tag, 'refresh setting isAdmin', false);
      this.isAdminInternal$.next(false);
      return;
    }

    const isAdmin = await this.userDataService
      .isAdmin(user.uid, accountId)
      .toPromise();
    console.debug(this.tag, 'refresh setting isAdmin', isAdmin);
    this.isAdminInternal$.next(isAdmin);
    this.isAdmin = isAdmin;
  }
}
