import { Injectable } from '@angular/core';
import { concatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { VasApi } from './vas/api/vas-api.service';
import { VasJobStatusDto } from '@ironcode/vas-lib';

@Injectable({
  providedIn: 'root'
})
export class JobStatusDataService {

  constructor(
    protected vasApi: VasApi
  ) {
  }

  getAll(): Observable<VasJobStatusDto[]> {
    return this.vasApi.account.read().pipe(
      map(accounts => accounts.map(a => a.id)),
      concatMap(async accountIds => {
        let results = [];
        for (let id of accountIds) {
          let r = await this.vasApi.jobStatus.read(id)
            .toPromise()
            .catch(() => []);
          if (!r || !r.length) {
            continue;
          }
          results = results.concat(r);
        }
        return results;
      })
    )
  }
}
