import { Injectable } from '@angular/core';
import { Filesystem } from '@capacitor/filesystem';
import { VasCameraControlValueModel } from '@ironcode/vas-lib';
import { UUID } from 'angular2-uuid';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { PhotoSaveQueueItem, SaveQueueItemState } from '../../models/vas/firebase/save-queue-item.model';
import { SaveQueueRepository } from '../../state/save-queue-repository.service';
import { isItemPhoto } from '../../util/is-item-photo';
import { FileStorageService } from '../file-storage.service';
import { JobDataService } from '../job-data.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root'
})
export class PhotoUploadService {

  constructor(
    protected saveQueueRepository: SaveQueueRepository,
    protected fileStorageService: FileStorageService,
    protected jobDataService: JobDataService
  ) {

  }

  uploadItem(item: PhotoSaveQueueItem): Observable<any> {

    if (!isItemPhoto(item)) {
      throw Error('item is not of type photo');
    }

    if (!item.item) {
      this.saveQueueRepository.deleteSaveQueueItem(item.id);
      return of(undefined);
    }

    const id = UUID.UUID();

    this.saveQueueRepository.setState(item.id, SaveQueueItemState.INITIALISING);

    return of(item).pipe(
      concatMap(item => this.getData(item)),
      map(data => this.fileStorageService.uploadContent(
        data,
        id
      )),
      concatMap(task => {
        return new Promise((resolve, reject) => {
          task.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.saveQueueRepository.setPercentage(item.id, progress);
              this.saveQueueRepository.setState(item.id, snapshot.state);
            },
            (error) => {
              this.saveQueueRepository.setError(item.id, error);
              reject(error);
            },
            () => {
              this.fileStorageService.getFileDownloadUrl(id).subscribe(
                url => {

                  const value = {
                    ...item.item,
                    url,
                    id
                  } as VasCameraControlValueModel;

                  console.debug(
                    '[PhotoUploadService] updating job',
                    item.jobId,
                    item.controlPath,
                    value
                  );
                  this.jobDataService.updateFieldPath(
                    item.jobId,
                    item.controlPath,
                    value
                  );
                  this.saveQueueRepository.deleteSaveQueueItem(item.id);
                  resolve(undefined);
                }
              );
            }
          );
        });
      }),
      catchError(error => {
        this.saveQueueRepository.updateSaveQueue(item.id, {state: 'error'});
        throw error;
      })
    );
  }

  /**
   * Returns the data as a Blob. First tries to get from the webPath (which is
   * an objectURL - a b64 of the image), if that fails we try to read from disk
   * @param item
   * @protected
   */
  protected async getData(item: PhotoSaveQueueItem): Promise<Blob> {

    if (!item.item.webPath && !item.item.path) {
      return Promise.reject(Error(`webPath and path are empty`));
    }

    try {
      return this.getDataFromObjectUrl(item.item.webPath).toPromise();
    } catch (e) {
      console.warn('Failed to read file data from ObjectUrl', e);
    }

    return this.getDataFromLocalStorage(item.item.path).toPromise();
  }

  protected getDataFromObjectUrl(objectUrl: string): Observable<Blob> {
    return fromPromise(this.atob(objectUrl));
  }

  protected getDataFromLocalStorage(path: string): Observable<Blob> {
    return fromPromise(Filesystem.readFile({
      path: path
    })).pipe(
      map(contents => contents.data),
      concatMap(data => this.atob(data))
    );
  }

  protected async atob(base64: string): Promise<Blob> {
    return await (await fetch(base64)).blob();
  }
}
