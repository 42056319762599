import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateStorageService } from './state-storage.service';
import { PersistableRepository } from './persistable.repository';
import { VasBranchDto } from '@ironcode/vas-lib';
import { AccountRepository } from './account.repository';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BranchRepository extends PersistableRepository {
  branches$: Observable<VasBranchDto[]>;

  protected store;

  constructor(
    protected stateStorageService: StateStorageService,
    protected accountRepo: AccountRepository
  ) {
    super(stateStorageService);
    const store = this.createStore();
    this.branches$ = combineLatest([
      store.pipe(selectAllEntities()),
      this.accountRepo.selectedAccountId$
    ]).pipe(
      map(([items, selectedAccountId]) => items
        .filter(item => item.account === selectedAccountId)
      )
    );
    this.store = store;
  }

  addBranch(branch: VasBranchDto) {
    this.store.update(addEntities(branch));
  }

  deleteBranch(id: VasBranchDto['id']) {
    this.store.update(deleteEntities(id));
  }

  getBranch(id: VasBranchDto['id']): Observable<VasBranchDto> {
    return this.store.pipe(selectEntity(id));
  }

  setBranches(branches: VasBranchDto[]) {
    this.store.update(setEntities(branches));
  }

  clearAndSet(branches: VasBranchDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {}
      }),
      setEntities(branches)
    );
  }

  updateBranch(id: VasBranchDto['id'], branch: Partial<VasBranchDto>) {
    this.store.update(updateEntities(id, branch));
  }

  upsertBranches(branches: VasBranchDto[]): void {
    this.store.update(upsertEntities(branches));
  }

  persist(): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    return this._persist('branch');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'branch'}, withEntities<VasBranchDto>());

    return store;
  }
}
