import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateStorageService } from './state-storage.service';
import { PersistableRepository } from './persistable.repository';
import { VasContactDto } from '@ironcode/vas-lib';
import { AccountRepository } from './account.repository';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactRepository extends PersistableRepository {

  contacts$: Observable<VasContactDto[]>;
  protected store;

  constructor(
    protected stateStorageService: StateStorageService,
    protected accountRepo: AccountRepository
  ) {
    super(stateStorageService);
    const store = this.createStore();

    this.contacts$ = combineLatest([
      store.pipe(selectAllEntities()),
      this.accountRepo.selectedAccountId$
    ]).pipe(
      map(([items, selectedAccountId]) => items
        .filter(item => item.account === selectedAccountId)
      )
    );
    this.store = store;
  }

  addContact(contact: VasContactDto) {
    this.store.update(addEntities(contact));
  }

  deleteContact(id: VasContactDto['id']) {
    this.store.update(deleteEntities(id));
  }

  getContact(id: VasContactDto['id']): Observable<VasContactDto> {
    return this.store.pipe(selectEntity(id));
  }

  setContacts(contacts: VasContactDto[]) {
    this.store.update(setEntities(contacts));
  }

  clearAndSet(contacts: VasContactDto[]) {
    this.store.update(
      state => ({
        ...state,
        ids: [],
        entities: {}
      }),
      setEntities(contacts)
    );
  }

  updateContact(id: VasContactDto['id'], contact: Partial<VasContactDto>) {
    this.store.update(updateEntities(id, contact));
  }

  upsertContacts(contacts: VasContactDto[]): void {
    this.store.update(upsertEntities(contacts));
  }

  persist(): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    return this._persist('contact');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'contact'}, withEntities<VasContactDto>());

    return store;
  }
}
