import { Observable } from 'rxjs';
import { persistState } from '@ngneat/elf-persist-state';
import { StateStorageService } from './state-storage.service';

export abstract class PersistableRepository {

  protected store;

  constructor(
    protected stateStorageService: StateStorageService
  ) {
  }

  abstract persist(): {initialized$: Observable<boolean>; unsubscribe(): void;}

  protected _persist(key: string): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    const $this = this;
    return persistState(this.store, {
      key,
      storage: {
        getItem(key: string) {
          return $this.stateStorageService.getItem(key)
        },
        removeItem(key: string) {
          return $this.stateStorageService.removeItem(key);
        },
        setItem(key: string, value: Record<string, any>) {
          return $this.stateStorageService.setItem(key, value);
        }
      },
    });
  }
}
