import { Injectable } from '@angular/core';
import { VasJobDto } from '@ironcode/vas-lib';
import { createStore, withProps } from '@ngneat/elf';
import { Store } from '@ngneat/elf/lib/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationProps } from '../../models/pagination-props';
import { JobDataServiceFilters } from '../../services/job-data.service';
import { PersistableRepository } from '../../state/persistable.repository';
import { StateStorageService } from '../../state/state-storage.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageProps {
  pagination: PaginationProps<VasJobDto>;
  filters: JobDataServiceFilters;
  networkAvailable: boolean | undefined;
  vasApiAuthenticated: boolean | undefined;
  vasApiAvailable: boolean | undefined;
  errors: Array<Error>;
}

@Injectable({
  providedIn: 'root'
})
export class HomePageRepository extends PersistableRepository {
  pagination$: Observable<PaginationProps<VasJobDto>>;
  errors$: Observable<Array<Error>>;
  filters$: Observable<JobDataServiceFilters>;
  store: Store<{
    name: string;
    state: HomePageProps;
    config: HomePageProps;
  }>;

  constructor(
    protected stateStorageService: StateStorageService
  ) {
    super(stateStorageService);
    this.store = this.createStore();
    this.pagination$ = this.store.pipe(
      map(state => state.pagination)
    );
    this.errors$ = this.store.pipe(
      map(state => state.errors)
    );
    this.filters$ = this.store.pipe(
      map(state => state.filters)
    );
  }

  addError(error: Error): void {
    this.store.update(
      state => ({
        ...state,
        errors: [...state.errors, error]
      })
    );
  }

  removeError(error: Error): void {
    this.store.update(
      state => ({
        ...state,
        errors: state.errors.filter(e => e !== error)
      })
    );
  }

  setErrors(errors: Array<Error>): void {
    this.store.update(
      state => ({
        ...state,
        errors
      })
    );
  }

  setOnlyMine(onlyMine: boolean): void {
    this.store.update(
      state => ({
        ...state,
        filters: {
          ...state.filters,
          onlyMine
        }
      })
    );
  }

  addLimit(amount: number): void {
    this.store.update(
      state => ({
        ...state,
        pagination: {
          ...state.pagination,
          limit: Math.min(state.pagination.limit + amount, 250)
        }
      })
    );
  }

  setJobStatusId(jobStatusId: string): void {
    this.store.update(
      state => ({
        ...state,
        filters: {
          ...state.filters,
          jobStatusId
        }
      })
    );
  }

  setLimit(limit: number): void {
    this.store.update(
      state => ({
        ...state,
        pagination: {
          ...state.pagination,
          limit
        }
      })
    );
  }

  setNetworkAvailable(networkAvailable: boolean): void {
    this.store.update(
      state => ({
        ...state,
        networkAvailable
      })
    );
  }

  setOffset(offset: VasJobDto, end: boolean = false): void {
    this.store.update(
      state => ({
        ...state,
        pagination: {
          ...state.pagination,
          offset,
          end
        }
      })
    );
  }

  setOrderBy(orderBy: string): void {
    this.store.update(
      state => ({
        ...state,
        pagination: {
          ...state.pagination,
          orderBy
        }
      })
    );
  }

  setOrderDirection(direction: 'asc' | 'desc'): void {
    this.store.update(
      state => ({
        ...state,
        pagination: {
          ...state.pagination,
          direction
        }
      })
    );
  }

  setVasApiAuthenticated(vasApiAuthenticated: boolean): void {
    this.store.update(
      state => ({
        ...state,
        vasApiAuthenticated
      })
    );
  }

  setVasApiAvailable(vasApiAvailable: boolean): void {
    this.store.update(
      state => ({
        ...state,
        vasApiAvailable
      })
    );
  }

  persist(): {
    initialized$: Observable<boolean>;
    unsubscribe(): void;
  } {
    return this._persist('homePage');
  }

  private createStore(): typeof store {
    const store = createStore({name: 'homePage'}, withProps<HomePageProps>({
      pagination: {
        offset: undefined,
        limit: 6,
        orderBy: 'created',
        orderDirection: 'desc',
        end: false
      },
      filters: {
        jobStatusId: undefined,
        onlyMine: true
      },
      networkAvailable: undefined,
      vasApiAuthenticated: undefined,
      vasApiAvailable: undefined,
      errors: []
    }));

    return store;
  }
}
